// The colors duke! The colors!
$color_dark:  #3c3c3c;
$color_mid:   #445566;
$color_light: #b8b8b8;

$color_teal: #005566;

// Fontsicles
$font_sans:  'Lato', sans-serif;
$font_serif: 'Lora', serif;

$gap: 32px;